@import url(https://fonts.googleapis.com/css2?family=Roboto%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C700%3B0%2C900&family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plasmic_ibeam_access_plasmic_tokens__3y1QK {
  --token-yqQ_hnf36: #82c341;
  --plasmic-token-ibeam-green: var(--token-yqQ_hnf36);
  --token-E66s0XP42: #939598;
  --plasmic-token-ibeam-grey: var(--token-E66s0XP42);
}

.plasmic_ibeam_access_plasmic_tokens__3y1QK {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_ibeam_access_plasmic_default_styles__QZ6-L {
  --mixin-S2OLrIGcf5_font-family: "Roboto", sans-serif;
  --mixin-S2OLrIGcf5_font-size: 16px;
  --mixin-S2OLrIGcf5_font-weight: 400;
  --mixin-S2OLrIGcf5_font-style: normal;
  --mixin-S2OLrIGcf5_color: #535353;
  --mixin-S2OLrIGcf5_text-align: left;
  --mixin-S2OLrIGcf5_text-transform: none;
  --mixin-S2OLrIGcf5_line-height: 1.5;
  --mixin-S2OLrIGcf5_letter-spacing: normal;
  --mixin-S2OLrIGcf5_white-space: pre-wrap;
  --mixin-S2OLrIGcf5_user-select: text;
  --mixin-S2OLrIGcf5_text-decoration-line: none;
  --mixin-S2OLrIGcf5_text-overflow: clip;
  --mixin-JcMFH7VxZN_font-family: "Inter", sans-serif;
  --mixin-JcMFH7VxZN_color: #000000;
  --mixin-JcMFH7VxZN_font-size: 72px;
  --mixin-JcMFH7VxZN_font-weight: 900;
  --mixin-JcMFH7VxZN_letter-spacing: -4px;
  --mixin-JcMFH7VxZN_line-height: 1;
  --mixin-JcMFH7VxZN_white-space: pre-wrap;
  --mixin-g-4V1D8W0g_font-family: "Inter", sans-serif;
  --mixin-g-4V1D8W0g_color: #000000;
  --mixin-g-4V1D8W0g_font-size: 48px;
  --mixin-g-4V1D8W0g_font-weight: 700;
  --mixin-g-4V1D8W0g_letter-spacing: -1px;
  --mixin-g-4V1D8W0g_line-height: 1.1;
  --mixin-g-4V1D8W0g_white-space: pre-wrap;
  --mixin-TqIWSlTL6B_font-family: "Inter", sans-serif;
  --mixin-TqIWSlTL6B_color: #000000;
  --mixin-TqIWSlTL6B_font-size: 32px;
  --mixin-TqIWSlTL6B_font-weight: 600;
  --mixin-TqIWSlTL6B_letter-spacing: -0.8px;
  --mixin-TqIWSlTL6B_line-height: 1.2;
  --mixin-TqIWSlTL6B_white-space: pre-wrap;
  --mixin-852KMCgDctm_font-family: "Inter", sans-serif;
  --mixin-852KMCgDctm_color: #000000;
  --mixin-852KMCgDctm_font-size: 24px;
  --mixin-852KMCgDctm_font-weight: 600;
  --mixin-852KMCgDctm_letter-spacing: -0.5px;
  --mixin-852KMCgDctm_line-height: 1.3;
  --mixin-852KMCgDctm_white-space: pre-wrap;
  --mixin-uclOyNsCzsM_font-family: "Inter", sans-serif;
  --mixin-uclOyNsCzsM_color: #000000;
  --mixin-uclOyNsCzsM_font-size: 20px;
  --mixin-uclOyNsCzsM_font-weight: 600;
  --mixin-uclOyNsCzsM_letter-spacing: -0.3px;
  --mixin-uclOyNsCzsM_line-height: 1.5;
  --mixin-uclOyNsCzsM_white-space: pre-wrap;
  --mixin-Ia5KU0oUu3F_font-family: "Inter", sans-serif;
  --mixin-Ia5KU0oUu3F_color: #000000;
  --mixin-Ia5KU0oUu3F_font-size: 16px;
  --mixin-Ia5KU0oUu3F_font-weight: 600;
  --mixin-Ia5KU0oUu3F_line-height: 1.5;
  --mixin-Ia5KU0oUu3F_white-space: pre-wrap;
  --mixin-9YZJbm-R5TK_color: #0070f3;
  --mixin-9YZJbm-R5TK_white-space: pre-wrap;
  --mixin-nOJEO7Otv2L_color: #3291ff;
  --mixin-nOJEO7Otv2L_white-space: pre-wrap;
  --mixin-qj68ArVPUyJ_border-left-color: #dddddd;
  --mixin-qj68ArVPUyJ_border-left-style: solid;
  --mixin-qj68ArVPUyJ_border-left-width: 3px;
  --mixin-qj68ArVPUyJ_color: #888888;
  --mixin-qj68ArVPUyJ_padding-left: 10px;
  --mixin-qj68ArVPUyJ_white-space: pre-wrap;
  --mixin-Y5s6I1ZwrVh_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-Y5s6I1ZwrVh_border-bottom-color: #dddddd;
  --mixin-Y5s6I1ZwrVh_border-bottom-style: solid;
  --mixin-Y5s6I1ZwrVh_border-bottom-width: 1px;
  --mixin-Y5s6I1ZwrVh_border-left-color: #dddddd;
  --mixin-Y5s6I1ZwrVh_border-left-style: solid;
  --mixin-Y5s6I1ZwrVh_border-left-width: 1px;
  --mixin-Y5s6I1ZwrVh_border-right-color: #dddddd;
  --mixin-Y5s6I1ZwrVh_border-right-style: solid;
  --mixin-Y5s6I1ZwrVh_border-right-width: 1px;
  --mixin-Y5s6I1ZwrVh_border-top-color: #dddddd;
  --mixin-Y5s6I1ZwrVh_border-top-style: solid;
  --mixin-Y5s6I1ZwrVh_border-top-width: 1px;
  --mixin-Y5s6I1ZwrVh_border-bottom-left-radius: 3px;
  --mixin-Y5s6I1ZwrVh_border-bottom-right-radius: 3px;
  --mixin-Y5s6I1ZwrVh_border-top-left-radius: 3px;
  --mixin-Y5s6I1ZwrVh_border-top-right-radius: 3px;
  --mixin-Y5s6I1ZwrVh_font-family: "Inconsolata";
  --mixin-Y5s6I1ZwrVh_padding-bottom: 1px;
  --mixin-Y5s6I1ZwrVh_padding-left: 4px;
  --mixin-Y5s6I1ZwrVh_padding-right: 4px;
  --mixin-Y5s6I1ZwrVh_padding-top: 1px;
  --mixin-Y5s6I1ZwrVh_white-space: pre-wrap;
  --mixin-rkMKReWogK3_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-rkMKReWogK3_border-bottom-color: #dddddd;
  --mixin-rkMKReWogK3_border-bottom-style: solid;
  --mixin-rkMKReWogK3_border-bottom-width: 1px;
  --mixin-rkMKReWogK3_border-left-color: #dddddd;
  --mixin-rkMKReWogK3_border-left-style: solid;
  --mixin-rkMKReWogK3_border-left-width: 1px;
  --mixin-rkMKReWogK3_border-right-color: #dddddd;
  --mixin-rkMKReWogK3_border-right-style: solid;
  --mixin-rkMKReWogK3_border-right-width: 1px;
  --mixin-rkMKReWogK3_border-top-color: #dddddd;
  --mixin-rkMKReWogK3_border-top-style: solid;
  --mixin-rkMKReWogK3_border-top-width: 1px;
  --mixin-rkMKReWogK3_border-bottom-left-radius: 3px;
  --mixin-rkMKReWogK3_border-bottom-right-radius: 3px;
  --mixin-rkMKReWogK3_border-top-left-radius: 3px;
  --mixin-rkMKReWogK3_border-top-right-radius: 3px;
  --mixin-rkMKReWogK3_font-family: "Inconsolata";
  --mixin-rkMKReWogK3_padding-bottom: 3px;
  --mixin-rkMKReWogK3_padding-left: 6px;
  --mixin-rkMKReWogK3_padding-right: 6px;
  --mixin-rkMKReWogK3_padding-top: 3px;
  --mixin-rkMKReWogK3_white-space: pre-wrap;
  --mixin-ar9mK02rr4U_display: flex;
  --mixin-ar9mK02rr4U_flex-direction: column;
  --mixin-ar9mK02rr4U_align-items: stretch;
  --mixin-ar9mK02rr4U_justify-content: flex-start;
  --mixin-ar9mK02rr4U_list-style-position: outside;
  --mixin-ar9mK02rr4U_padding-left: 40px;
  --mixin-ar9mK02rr4U_position: relative;
  --mixin-ar9mK02rr4U_list-style-type: decimal;
  --mixin-ar9mK02rr4U_white-space: pre-wrap;
  --mixin-qWWAyoh3r7Z_display: flex;
  --mixin-qWWAyoh3r7Z_flex-direction: column;
  --mixin-qWWAyoh3r7Z_align-items: stretch;
  --mixin-qWWAyoh3r7Z_justify-content: flex-start;
  --mixin-qWWAyoh3r7Z_list-style-position: outside;
  --mixin-qWWAyoh3r7Z_padding-left: 40px;
  --mixin-qWWAyoh3r7Z_position: relative;
  --mixin-qWWAyoh3r7Z_list-style-type: disc;
  --mixin-qWWAyoh3r7Z_white-space: pre-wrap;
}

:where(.plasmic_ibeam_access_all__1CHfq) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.plasmic_ibeam_access_img__2zjqW) {
  display: inline-block;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG img) {
  white-space: inherit;
}

:where(.plasmic_ibeam_access_li__KvaZQ) {
  display: list-item;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG li) {
  white-space: inherit;
}

:where(.plasmic_ibeam_access_span__3M_cn) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_ibeam_access_input__2ZEy-) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_ibeam_access_textarea__3xeKU) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_ibeam_access_button__1HxOc) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.plasmic_ibeam_access_code__3aL6v) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_ibeam_access_pre__1ErTE) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_ibeam_access_p__1A2vz) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_ibeam_access_h1__3hF1K) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_ibeam_access_h2__2sUyx) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_ibeam_access_h3__22jb4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_ibeam_access_h4__204Cs) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_ibeam_access_h5__3I4e5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_ibeam_access_h6__2wug7) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_ibeam_access_address__3D5cr) {
  font-style: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.plasmic_ibeam_access_a__3LNSb) {
  color: inherit;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG a) {
  white-space: inherit;
  color: inherit;
}

:where(.plasmic_ibeam_access_ol__1J3hR) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_ibeam_access_ul__34ogP) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_ibeam_access_select__2xY_Z) {
  padding: 2px 6px;
}
:where(.plasmic_ibeam_access___wab_expr_html_text__b1ISG select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_ibeam_access_plasmic_default__component_wrapper__2tE_- {
  display: grid;
}
.plasmic_ibeam_access_plasmic_default__inline__I15GQ {
  display: inline;
}
.plasmic_ibeam_access_plasmic_page_wrapper__bRT_F {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_ibeam_access_plasmic_page_wrapper__bRT_F > * {
  height: auto !important;
}
.plasmic_ibeam_access___wab_expr_html_text__b1ISG {
  white-space: normal;
}
:where(.plasmic_ibeam_access_root_reset__2bCRb) {
  font-family: var(--mixin-S2OLrIGcf5_font-family);
  font-size: var(--mixin-S2OLrIGcf5_font-size);
  font-weight: var(--mixin-S2OLrIGcf5_font-weight);
  font-style: var(--mixin-S2OLrIGcf5_font-style);
  color: var(--mixin-S2OLrIGcf5_color);
  text-align: var(--mixin-S2OLrIGcf5_text-align);
  text-transform: var(--mixin-S2OLrIGcf5_text-transform);
  line-height: var(--mixin-S2OLrIGcf5_line-height);
  letter-spacing: var(--mixin-S2OLrIGcf5_letter-spacing);
  white-space: var(--mixin-S2OLrIGcf5_white-space);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) h1:where(.plasmic_ibeam_access_h1__3hF1K),
h1:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_h1__3hF1K),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) h1,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) h1,
h1:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  font-family: var(--mixin-JcMFH7VxZN_font-family);
  color: var(--mixin-JcMFH7VxZN_color);
  font-size: var(--mixin-JcMFH7VxZN_font-size);
  font-weight: var(--mixin-JcMFH7VxZN_font-weight);
  letter-spacing: var(--mixin-JcMFH7VxZN_letter-spacing);
  line-height: var(--mixin-JcMFH7VxZN_line-height);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) h2:where(.plasmic_ibeam_access_h2__2sUyx),
h2:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_h2__2sUyx),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) h2,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) h2,
h2:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  font-family: var(--mixin-g-4V1D8W0g_font-family);
  color: var(--mixin-g-4V1D8W0g_color);
  font-size: var(--mixin-g-4V1D8W0g_font-size);
  font-weight: var(--mixin-g-4V1D8W0g_font-weight);
  letter-spacing: var(--mixin-g-4V1D8W0g_letter-spacing);
  line-height: var(--mixin-g-4V1D8W0g_line-height);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) h3:where(.plasmic_ibeam_access_h3__22jb4),
h3:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_h3__22jb4),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) h3,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) h3,
h3:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  font-family: var(--mixin-TqIWSlTL6B_font-family);
  color: var(--mixin-TqIWSlTL6B_color);
  font-size: var(--mixin-TqIWSlTL6B_font-size);
  font-weight: var(--mixin-TqIWSlTL6B_font-weight);
  letter-spacing: var(--mixin-TqIWSlTL6B_letter-spacing);
  line-height: var(--mixin-TqIWSlTL6B_line-height);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) h4:where(.plasmic_ibeam_access_h4__204Cs),
h4:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_h4__204Cs),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) h4,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) h4,
h4:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  font-family: var(--mixin-852KMCgDctm_font-family);
  color: var(--mixin-852KMCgDctm_color);
  font-size: var(--mixin-852KMCgDctm_font-size);
  font-weight: var(--mixin-852KMCgDctm_font-weight);
  letter-spacing: var(--mixin-852KMCgDctm_letter-spacing);
  line-height: var(--mixin-852KMCgDctm_line-height);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) h5:where(.plasmic_ibeam_access_h5__3I4e5),
h5:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_h5__3I4e5),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) h5,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) h5,
h5:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  font-family: var(--mixin-uclOyNsCzsM_font-family);
  color: var(--mixin-uclOyNsCzsM_color);
  font-size: var(--mixin-uclOyNsCzsM_font-size);
  font-weight: var(--mixin-uclOyNsCzsM_font-weight);
  letter-spacing: var(--mixin-uclOyNsCzsM_letter-spacing);
  line-height: var(--mixin-uclOyNsCzsM_line-height);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) h6:where(.plasmic_ibeam_access_h6__2wug7),
h6:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_h6__2wug7),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) h6,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) h6,
h6:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  font-family: var(--mixin-Ia5KU0oUu3F_font-family);
  color: var(--mixin-Ia5KU0oUu3F_color);
  font-size: var(--mixin-Ia5KU0oUu3F_font-size);
  font-weight: var(--mixin-Ia5KU0oUu3F_font-weight);
  line-height: var(--mixin-Ia5KU0oUu3F_line-height);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) a:where(.plasmic_ibeam_access_a__3LNSb),
a:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_a__3LNSb),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) a,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) a,
a:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  color: var(--mixin-9YZJbm-R5TK_color);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) a:where(.plasmic_ibeam_access_a__3LNSb):hover,
a:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_a__3LNSb):hover,
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) a:hover,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) a:hover,
a:where(.plasmic_ibeam_access_root_reset_tags__YbVsD):hover {
  color: var(--mixin-nOJEO7Otv2L_color);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) blockquote:where(.plasmic_ibeam_access_blockquote__3yXTu),
blockquote:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_blockquote__3yXTu),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) blockquote,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) blockquote,
blockquote:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  color: var(--mixin-qj68ArVPUyJ_color);
  padding-left: var(--mixin-qj68ArVPUyJ_padding-left);
  border-left: var(--mixin-qj68ArVPUyJ_border-left-width)
    var(--mixin-qj68ArVPUyJ_border-left-style)
    var(--mixin-qj68ArVPUyJ_border-left-color);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) code:where(.plasmic_ibeam_access_code__3aL6v),
code:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_code__3aL6v),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) code,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) code,
code:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  background: #f8f8f8;
  font-family: var(--mixin-Y5s6I1ZwrVh_font-family);
  border-radius: var(--mixin-Y5s6I1ZwrVh_border-top-left-radius)
    var(--mixin-Y5s6I1ZwrVh_border-top-right-radius)
    var(--mixin-Y5s6I1ZwrVh_border-bottom-right-radius)
    var(--mixin-Y5s6I1ZwrVh_border-bottom-left-radius);
  padding: var(--mixin-Y5s6I1ZwrVh_padding-top)
    var(--mixin-Y5s6I1ZwrVh_padding-right)
    var(--mixin-Y5s6I1ZwrVh_padding-bottom)
    var(--mixin-Y5s6I1ZwrVh_padding-left);
  border-top: var(--mixin-Y5s6I1ZwrVh_border-top-width)
    var(--mixin-Y5s6I1ZwrVh_border-top-style)
    var(--mixin-Y5s6I1ZwrVh_border-top-color);
  border-right: var(--mixin-Y5s6I1ZwrVh_border-right-width)
    var(--mixin-Y5s6I1ZwrVh_border-right-style)
    var(--mixin-Y5s6I1ZwrVh_border-right-color);
  border-bottom: var(--mixin-Y5s6I1ZwrVh_border-bottom-width)
    var(--mixin-Y5s6I1ZwrVh_border-bottom-style)
    var(--mixin-Y5s6I1ZwrVh_border-bottom-color);
  border-left: var(--mixin-Y5s6I1ZwrVh_border-left-width)
    var(--mixin-Y5s6I1ZwrVh_border-left-style)
    var(--mixin-Y5s6I1ZwrVh_border-left-color);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) pre:where(.plasmic_ibeam_access_pre__1ErTE),
pre:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_pre__1ErTE),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) pre,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) pre,
pre:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  background: #f8f8f8;
  font-family: var(--mixin-rkMKReWogK3_font-family);
  border-radius: var(--mixin-rkMKReWogK3_border-top-left-radius)
    var(--mixin-rkMKReWogK3_border-top-right-radius)
    var(--mixin-rkMKReWogK3_border-bottom-right-radius)
    var(--mixin-rkMKReWogK3_border-bottom-left-radius);
  padding: var(--mixin-rkMKReWogK3_padding-top)
    var(--mixin-rkMKReWogK3_padding-right)
    var(--mixin-rkMKReWogK3_padding-bottom)
    var(--mixin-rkMKReWogK3_padding-left);
  border-top: var(--mixin-rkMKReWogK3_border-top-width)
    var(--mixin-rkMKReWogK3_border-top-style)
    var(--mixin-rkMKReWogK3_border-top-color);
  border-right: var(--mixin-rkMKReWogK3_border-right-width)
    var(--mixin-rkMKReWogK3_border-right-style)
    var(--mixin-rkMKReWogK3_border-right-color);
  border-bottom: var(--mixin-rkMKReWogK3_border-bottom-width)
    var(--mixin-rkMKReWogK3_border-bottom-style)
    var(--mixin-rkMKReWogK3_border-bottom-color);
  border-left: var(--mixin-rkMKReWogK3_border-left-width)
    var(--mixin-rkMKReWogK3_border-left-style)
    var(--mixin-rkMKReWogK3_border-left-color);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) ol:where(.plasmic_ibeam_access_ol__1J3hR),
ol:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_ol__1J3hR),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) ol,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) ol,
ol:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  display: var(--mixin-ar9mK02rr4U_display);
  flex-direction: var(--mixin-ar9mK02rr4U_flex-direction);
  align-items: var(--mixin-ar9mK02rr4U_align-items);
  justify-content: var(--mixin-ar9mK02rr4U_justify-content);
  list-style-position: var(--mixin-ar9mK02rr4U_list-style-position);
  padding-left: var(--mixin-ar9mK02rr4U_padding-left);
  position: var(--mixin-ar9mK02rr4U_position);
  list-style-type: var(--mixin-ar9mK02rr4U_list-style-type);
  flex-column-gap: var(--mixin-ar9mK02rr4U_flex-column-gap);
}

:where(.plasmic_ibeam_access_root_reset__2bCRb) ul:where(.plasmic_ibeam_access_ul__34ogP),
ul:where(.plasmic_ibeam_access_root_reset__2bCRb.plasmic_ibeam_access_ul__34ogP),
:where(.plasmic_ibeam_access_root_reset__2bCRb .plasmic_ibeam_access___wab_expr_html_text__b1ISG) ul,
:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) ul,
ul:where(.plasmic_ibeam_access_root_reset_tags__YbVsD) {
  display: var(--mixin-qWWAyoh3r7Z_display);
  flex-direction: var(--mixin-qWWAyoh3r7Z_flex-direction);
  align-items: var(--mixin-qWWAyoh3r7Z_align-items);
  justify-content: var(--mixin-qWWAyoh3r7Z_justify-content);
  list-style-position: var(--mixin-qWWAyoh3r7Z_list-style-position);
  padding-left: var(--mixin-qWWAyoh3r7Z_padding-left);
  position: var(--mixin-qWWAyoh3r7Z_position);
  list-style-type: var(--mixin-qWWAyoh3r7Z_list-style-type);
  flex-column-gap: var(--mixin-qWWAyoh3r7Z_flex-column-gap);
}

.PlasmicLoginPage_root__2vesH {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  min-width: 0;
}
.PlasmicLoginPage_freeBox__nxghs__3MQAH {
  display: flex;
  position: relative;
  flex-direction: column;
  background: linear-gradient(0deg, #939598 0%, #8da780 35%, #82c341 100%) 0% 0% /
      100% 100% repeat,
    #82c341;
  height: 100%;
  min-height: 0;
}
.PlasmicLoginPage_freeBox__nxghs__3MQAH > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: unset;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicLoginPage_freeBox__nxghs__3MQAH > .__wab_flex-container > *,
.PlasmicLoginPage_freeBox__nxghs__3MQAH > .__wab_flex-container > .__wab_slot > *,
.PlasmicLoginPage_freeBox__nxghs__3MQAH > .__wab_flex-container > picture > img,
.PlasmicLoginPage_freeBox__nxghs__3MQAH
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 20px;
}
.PlasmicLoginPage_freeBox__nxghs__3MQAH {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #939598 0%, #8da780 35%, #82c341 100%) 0% 0% /
      100% 100% repeat,
    #82c341;
  height: 100%;
  align-content: unset;
  min-height: 0;
}
.PlasmicLoginPage_freeBox__ndtWe__HUpJx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicLoginPage_welcome__B2Erc {
  position: relative;
  font-size: 48px;
  color: #ffffff;
  text-align: center;
}
.PlasmicLoginPage_text__1qqEh {
  position: relative;
  text-align: center;
  color: #ffffff;
}
.PlasmicLoginPage_freeBox__qun8F__344Ao {
  display: flex;
  position: relative;
  flex-direction: column;
  align-self: center;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  padding: 50px;
  border-width: 1px;
}
.PlasmicLoginPage_freeBox__qun8F__344Ao > .__wab_flex-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: unset;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicLoginPage_freeBox__qun8F__344Ao > .__wab_flex-container > *,
.PlasmicLoginPage_freeBox__qun8F__344Ao > .__wab_flex-container > .__wab_slot > *,
.PlasmicLoginPage_freeBox__qun8F__344Ao > .__wab_flex-container > picture > img,
.PlasmicLoginPage_freeBox__qun8F__344Ao
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 20px;
}
.PlasmicLoginPage_freeBox__qun8F__344Ao {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: unset;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  padding: 50px;
  border-width: 1px;
}
.PlasmicLoginPage_freeBox__e0W28__jEaqr {
  display: flex;
  position: relative;
  flex-direction: column;
}
.PlasmicLoginPage_freeBox__e0W28__jEaqr > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicLoginPage_freeBox__e0W28__jEaqr > .__wab_flex-container > *,
.PlasmicLoginPage_freeBox__e0W28__jEaqr > .__wab_flex-container > .__wab_slot > *,
.PlasmicLoginPage_freeBox__e0W28__jEaqr > .__wab_flex-container > picture > img,
.PlasmicLoginPage_freeBox__e0W28__jEaqr
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 20px;
}
.PlasmicLoginPage_freeBox__e0W28__jEaqr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicLoginPage_freeBoxloading__e0W2809Hv2__1ySE8 {
  display: none;
}
.PlasmicLoginPage_freeBoxloading__e0W2809Hv2__1ySE8 {
  display: none;
}
.PlasmicLoginPage_materialUiTextField___7MwH__2aG5D.__wab_instance {
  position: relative;
}
.PlasmicLoginPage_materialUiTextField__jf908__9ujal.__wab_instance {
  position: relative;
}
.PlasmicLoginPage_freeBox__m8B8G__1lL-G {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicLoginPage_freeBoxloading__m8B8G09Hv2__3Cw3d {
  width: 200px;
  align-self: center;
}
.PlasmicLoginPage_materialUiCircularProgressloading__1U1Fw.__wab_instance {
  align-self: center;
}
.PlasmicLoginPage_signInButton__3TmSS {
  position: relative;
  background: #82c341;
  color: #ffffff;
  font-weight: 400;
  height: auto;
  border-radius: 5px;
}
.PlasmicLoginPage_signInButtonloading__EIc07 {
  display: none;
}
.PlasmicLoginPage_img__2_y97 {
  position: relative;
  object-fit: scale-down;
  width: 180px;
  height: 81px;
  flex-shrink: 0;
}
.PlasmicLoginPage_img__2_y97 > picture > img {
  object-fit: scale-down;
}
.PlasmicLoginPage_img__2_y97 > .__wab_img-spacer > img {
  object-fit: scale-down;
}

.PlasmicAppBar_root__2Jprc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  height: auto;
  background: #ffffff;
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 2px 4px 4px 2px #00000080;
  position: relative;
  min-width: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.PlasmicAppBar_freeBox___3Qks1__3-HAm {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  padding-left: 20px;
  min-width: 0;
}
.PlasmicAppBar_img___5PibA__efTYE {
  position: relative;
  object-fit: contain;
  height: 50px;
  width: 100px;
  flex-shrink: 0;
}
.PlasmicAppBar_img___5PibA__efTYE > picture > img {
  object-fit: contain;
}
.PlasmicAppBar_img___5PibA__efTYE > .__wab_img-spacer > img {
  object-fit: contain;
}
.PlasmicAppBar_text__TlA52 {
  position: relative;
  font-size: 48px;
  color: #535353fa;
  font-weight: 400;
}
.PlasmicAppBar_freeBox__rokOg__3fRei {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: center;
  width: 100%;
  padding-right: 20px;
  min-width: 0;
}
.PlasmicAppBar_freeBox__rokOg__3fRei > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-content: unset;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.PlasmicAppBar_freeBox__rokOg__3fRei > .__wab_flex-container > *,
.PlasmicAppBar_freeBox__rokOg__3fRei > .__wab_flex-container > .__wab_slot > *,
.PlasmicAppBar_freeBox__rokOg__3fRei > .__wab_flex-container > picture > img,
.PlasmicAppBar_freeBox__rokOg__3fRei
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 20px;
}
.PlasmicAppBar_freeBox__rokOg__3fRei {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
  width: 100%;
  align-content: unset;
  padding-right: 20px;
  min-width: 0;
}
.PlasmicAppBar_svg__xgpr8__2pJsM {
  position: relative;
  object-fit: cover;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}

.PlasmicJobItem_rootBox__1wAha {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 500px;
  position: relative;
  box-shadow: none;
  background: #ffffff;
  cursor: pointer;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transform: translateX(0px) translateY(0px) translateZ(0px);
          transform: translateX(0px) translateY(0px) translateZ(0px);
  backdrop-filter: none;
  -webkit-filter: none;
          filter: none;
  -webkit-backdrop-filter: none;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-radius: 5px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_rootBox__1wAha {
    width: auto;
    display: inline-flex;
  }
}
.PlasmicJobItem_freeBox__acEpO__3rSqA {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.PlasmicJobItem_freeBox__acEpO__3rSqA > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.PlasmicJobItem_freeBox__acEpO__3rSqA > .__wab_flex-container > *,
.PlasmicJobItem_freeBox__acEpO__3rSqA > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobItem_freeBox__acEpO__3rSqA > .__wab_flex-container > picture > img,
.PlasmicJobItem_freeBox__acEpO__3rSqA
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 0px;
}
.PlasmicJobItem_freeBox__acEpO__3rSqA {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  width: auto;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.PlasmicJobItem_freeBox___3Xzm4__wIaTO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  min-width: 0;
}
.PlasmicJobItem_freeBox__wetvu__1av54 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  padding-left: 20px;
  padding-right: 20px;
}
.PlasmicJobItem_text__ys2AQ__UjwTN {
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_text__ys2AQ__UjwTN {
    font-size: 12px;
  }
}
.PlasmicJobItem_freeBox__lhzu4__215w8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: unset;
  height: 100%;
  min-height: 0;
}
.PlasmicJobItem_freeBox__uu0A__2MXz8 {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 35px;
  width: 98px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_freeBox__uu0A__2MXz8 {
    height: 25px;
    width: auto;
    flex-shrink: 0;
  }
}
.PlasmicJobItem_slotTargetJobNumber__2VsD1 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_slotTargetJobNumber__2VsD1 {
    font-size: 18px;
  }
}
.PlasmicJobItem_freeBox___34Rq6__3IHDz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  min-width: 0;
}
.PlasmicJobItem_freeBox__tyYIq__3uw3H {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  padding: 0px 20px;
}
.PlasmicJobItem_text__o7JV7__24r7t {
  color: #ffffff;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_text__o7JV7__24r7t {
    height: auto;
    font-size: 12px;
  }
}
.PlasmicJobItem_freeBox__cb9J__1eZzR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: unset;
  height: 100%;
  min-height: 0;
  padding: 10px;
}
.PlasmicJobItem_freeBox__rJSfz__AStTh {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20px;
  width: 37px;
  flex-shrink: 0;
}
.PlasmicJobItem_slotTargetYear__1k2H8 {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_slotTargetYear__1k2H8 {
    font-size: 12px;
  }
}
.PlasmicJobItem_slotTargetDate__2D1Fu {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_slotTargetDate__2D1Fu {
    font-size: 18px;
  }
}
.PlasmicJobItem_freeBox__zARt__CwHWA {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20px;
  width: 83px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_freeBox__zARt__CwHWA {
    height: 20px;
    width: auto;
    flex-shrink: 0;
  }
}
.PlasmicJobItem_slotTargetTime__-OTts {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_slotTargetTime__-OTts {
    font-size: 12px;
  }
}
.PlasmicJobItem_freeBox__hw4Kd__35fo2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  min-width: 0;
}
.PlasmicJobItem_freeBox__yl2U__1wGKY {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  padding: 0px 20px;
}
.PlasmicJobItem_text__biOv__2xy2U {
  color: #ffffff;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_text__biOv__2xy2U {
    height: auto;
    font-size: 12px;
  }
}
.PlasmicJobItem_freeBox__oLk1D__3v-4E {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: unset;
  height: 100%;
  width: 180px;
  min-height: 0;
  padding: 10px;
}
.PlasmicJobItem_southAustralia__2nSOW {
  position: relative;
  text-align: center;
  height: 20px;
  width: auto;
  flex-shrink: 0;
}
.PlasmicJobItem_slotTargetStreet__1HN2a {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_slotTargetStreet__1HN2a {
    font-size: 18px;
  }
}
.PlasmicJobItem_freeBox__l08X8__3tLMN {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20px;
  width: auto;
  flex-shrink: 0;
}
.PlasmicJobItem_slotTargetSuburb__2H0GM {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobItem_slotTargetSuburb__2H0GM {
    font-size: 12px;
  }
}

.PlasmicJobs_root__3GuH0 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
  border-width: 1px;
}
.PlasmicJobs_root__3GuH0 > .__wab_flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.PlasmicJobs_root__3GuH0 > .__wab_flex-container > *,
.PlasmicJobs_root__3GuH0 > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobs_root__3GuH0 > .__wab_flex-container > picture > img,
.PlasmicJobs_root__3GuH0 > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 50px;
}
.PlasmicJobs_root__3GuH0 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: unset;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
  border-width: 1px;
}
.PlasmicJobs_appBar__19kQp.__wab_instance {
  position: relative;
  z-index: 2;
}
.PlasmicJobs_img__dsbij__3h0vl {
  position: relative;
  object-fit: contain;
  height: 50px;
  width: 100px;
}
.PlasmicJobs_img__dsbij__3h0vl > picture > img {
  object-fit: contain;
}
.PlasmicJobs_img__dsbij__3h0vl > .__wab_img-spacer > img {
  object-fit: contain;
}
.PlasmicJobs_svg__o2S54__YGIdC {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicJobs_freeBox___0FHfM__3CdAu {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 500px;
  min-height: 0;
}
.PlasmicJobs_freeBox___0FHfM__3CdAu > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicJobs_freeBox___0FHfM__3CdAu > .__wab_flex-container > *,
.PlasmicJobs_freeBox___0FHfM__3CdAu > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobs_freeBox___0FHfM__3CdAu > .__wab_flex-container > picture > img,
.PlasmicJobs_freeBox___0FHfM__3CdAu
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 20px;
}
.PlasmicJobs_freeBox___0FHfM__3CdAu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  width: 500px;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobs_freeBox___0FHfM__3CdAu {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobs_freeBox___0FHfM__3CdAu > .__wab_flex-container {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobs_freeBox___0FHfM__3CdAu {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 0;
  }
}
.PlasmicJobs_freeBox__jyNoq__3OriV {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicJobs_text__2MAMi {
  position: relative;
  color: #ffffff;
  font-size: 42px;
}
.PlasmicJobs_freeBox__bySWw__1wq1a {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicJobs_textInput__tlB__20vqJ {
  width: 180px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px 0px 0px 10px;
  border: 2px solid var(--token-E66s0XP42);
}
.PlasmicJobs_button__pDt5R__2V5Gz {
  position: relative;
  background: var(--token-E66s0XP42);
  color: #ffffff;
  font-weight: 700;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid var(--token-E66s0XP42);
}
.PlasmicJobs_freeBox___0Ocr__1qRex {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 2;
  margin-bottom: 20px;
}
.PlasmicJobs_freeBox___0Ocr__1qRex > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicJobs_freeBox___0Ocr__1qRex > .__wab_flex-container > *,
.PlasmicJobs_freeBox___0Ocr__1qRex > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobs_freeBox___0Ocr__1qRex > .__wab_flex-container > picture > img,
.PlasmicJobs_freeBox___0Ocr__1qRex
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 20px;
}
.PlasmicJobs_freeBox___0Ocr__1qRex {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 2;
  margin-bottom: 20px;
}
.PlasmicJobs_jobItem__fhKM__1SMbB.__wab_instance {
  position: relative;
  z-index: 2;
}

.PlasmicJobArtefact_root__2mDU1 {
  display: inline-flex;
  flex-direction: row;
  width: auto;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-style: solid;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px;
}
.PlasmicJobArtefact_root__2mDU1 > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.PlasmicJobArtefact_root__2mDU1 > .__wab_flex-container > *,
.PlasmicJobArtefact_root__2mDU1 > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobArtefact_root__2mDU1 > .__wab_flex-container > picture > img,
.PlasmicJobArtefact_root__2mDU1 > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 0px;
}
.PlasmicJobArtefact_root__2mDU1 {
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-style: solid;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px;
}
.PlasmicJobArtefact_freeBox___8KgBg__139WR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 120px;
  align-self: center;
  flex-shrink: 0;
}
.PlasmicJobArtefact_slotTargetType__3qYqs {
  text-align: center;
}
.PlasmicJobArtefact_freeBox__sRxnW__2zakS {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 80px;
  align-self: center;
  flex-shrink: 0;
}
.PlasmicJobArtefact_slotTargetTime__3-qL8 {
  text-align: center;
}
.PlasmicJobArtefact_freeBox___0D2Yr__1wL-M {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  align-self: center;
  min-width: 0;
}
.PlasmicJobArtefact_freeBox___551Jw__3U2RK {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20px;
  width: auto;
  flex-shrink: 0;
}

.PlasmicJobsView_root__2wXMJ {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  transition-property: all;
  transition-duration: 1s;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-width: 1px;
}
.PlasmicJobsView_root__2wXMJ > .__wab_flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.PlasmicJobsView_root__2wXMJ > .__wab_flex-container > *,
.PlasmicJobsView_root__2wXMJ > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobsView_root__2wXMJ > .__wab_flex-container > picture > img,
.PlasmicJobsView_root__2wXMJ > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 50px;
}
.PlasmicJobsView_root__2wXMJ {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: unset;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  transition-property: all;
  transition-duration: 1s;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-width: 1px;
}
.PlasmicJobsView_appBar__1Qr9J.__wab_instance {
  position: relative;
  z-index: 2;
}
.PlasmicJobsView_img__joNiu__3O-LK {
  position: relative;
  object-fit: contain;
  height: 50px;
  width: 100px;
}
.PlasmicJobsView_img__joNiu__3O-LK > picture > img {
  object-fit: contain;
}
.PlasmicJobsView_img__joNiu__3O-LK > .__wab_img-spacer > img {
  object-fit: contain;
}
.PlasmicJobsView_svg__dGkee__2k4Ts {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicJobsView_freeBox__wtvI__3y9qR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicJobsView_freeBoxloading__wtvIgcpuk__3ekHY {
  height: 100%;
  width: auto;
  min-height: 0;
}
.PlasmicJobsView_freeBox__i5YbL__1RN5S {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
.PlasmicJobsView_freeBox__i5YbL__1RN5S > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicJobsView_freeBox__i5YbL__1RN5S > .__wab_flex-container > *,
.PlasmicJobsView_freeBox__i5YbL__1RN5S > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobsView_freeBox__i5YbL__1RN5S > .__wab_flex-container > picture > img,
.PlasmicJobsView_freeBox__i5YbL__1RN5S
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 20px;
}
.PlasmicJobsView_freeBox__i5YbL__1RN5S {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_freeBox__i5YbL__1RN5S {
    padding: 20px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_freeBox__i5YbL__1RN5S {
    padding: 20px;
  }
}
.PlasmicJobsView_freeBoxloading__i5YbLgcpuk__3RyX7 {
  display: none;
}
.PlasmicJobsView_freeBoxloading__i5YbLgcpuk__3RyX7 {
  display: none;
}
.PlasmicJobsView_freeBox__vopsq__1LMe_ {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicJobsView_freeBox__y7Suo__3vfHH {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_freeBox__y7Suo__3vfHH {
    justify-content: center;
    align-items: center;
  }
}
.PlasmicJobsView_slotTargetJobNumber__m522h {
  font-size: 42px;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_slotTargetJobNumber__m522h {
    text-align: center;
  }
}
.PlasmicJobsView_svg__p3XMb__1yY1y {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.PlasmicJobsView_freeBox__qc5Ao__p2a7x {
  display: flex;
  position: relative;
  flex-direction: row;
}
.PlasmicJobsView_freeBox__qc5Ao__p2a7x > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.PlasmicJobsView_freeBox__qc5Ao__p2a7x > .__wab_flex-container > *,
.PlasmicJobsView_freeBox__qc5Ao__p2a7x > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobsView_freeBox__qc5Ao__p2a7x > .__wab_flex-container > picture > img,
.PlasmicJobsView_freeBox__qc5Ao__p2a7x
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 10px;
}
.PlasmicJobsView_freeBox__qc5Ao__p2a7x {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_freeBox__qc5Ao__p2a7x > .__wab_flex-container {
    justify-content: center;
    align-items: center;
    align-content: unset;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_freeBox__qc5Ao__p2a7x {
    justify-content: center;
    align-items: center;
    align-content: unset;
  }
}
.PlasmicJobsView_text__fu29__Ifigd {
  position: relative;
  color: #ffffff;
}
.PlasmicJobsView_text__st5Wd__3VkXT {
  position: relative;
  color: #ffffff;
}
.PlasmicJobsView_text__qPdJn__2xGzz {
  position: relative;
  color: #ffffff;
}
.PlasmicJobsView_freeBox__jZiH__K8mPb {
  display: flex;
  position: relative;
  flex-direction: row;
}
.PlasmicJobsView_freeBox__jZiH__K8mPb > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.PlasmicJobsView_freeBox__jZiH__K8mPb > .__wab_flex-container > *,
.PlasmicJobsView_freeBox__jZiH__K8mPb > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobsView_freeBox__jZiH__K8mPb > .__wab_flex-container > picture > img,
.PlasmicJobsView_freeBox__jZiH__K8mPb
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 20px;
}
.PlasmicJobsView_freeBox__jZiH__K8mPb {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_freeBox__jZiH__K8mPb > .__wab_flex-container {
    flex-wrap: wrap;
    align-content: center;
    align-items: unset;
    justify-content: center;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_freeBox__jZiH__K8mPb {
    flex-wrap: wrap;
    align-content: center;
    align-items: unset;
    justify-content: center;
  }
}
.PlasmicJobsView_freeBox___7NjMp__ljjQf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
}
.PlasmicJobsView_text__fw6Ei__3iAsb {
  position: relative;
  color: #ffffff;
}
.PlasmicJobsView_text__bzhyG__27DF4 {
  position: relative;
  color: #ffffff;
}
.PlasmicJobsView_freeBox__zr48H__2lXhj {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 450px;
  height: 300px;
  flex-shrink: 0;
  border-width: 0px;
  border-style: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_freeBox__zr48H__2lXhj {
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
.PlasmicJobsView_artefactsBox__3gvmn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  position: relative;
  box-shadow: none;
  background: #ffffff;
  cursor: pointer;
  transition-property: all;
  transition-duration: 1s;
  -webkit-transform: translateX(0px) translateY(0px) translateZ(0px);
          transform: translateX(0px) translateY(0px) translateZ(0px);
  backdrop-filter: none;
  -webkit-filter: none;
          filter: none;
  padding-bottom: 0px;
  margin-bottom: 20px;
  -webkit-backdrop-filter: none;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-radius: 5px;
}
.PlasmicJobsView_freeBox__phhL__lZg1D {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  border-radius: 5px 5px 0px 0px;
  border-width: 0px;
  border-style: solid;
}
.PlasmicJobsView_freeBox__phhL__lZg1D > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.PlasmicJobsView_freeBox__phhL__lZg1D > .__wab_flex-container > *,
.PlasmicJobsView_freeBox__phhL__lZg1D > .__wab_flex-container > .__wab_slot > *,
.PlasmicJobsView_freeBox__phhL__lZg1D > .__wab_flex-container > picture > img,
.PlasmicJobsView_freeBox__phhL__lZg1D
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 0px;
}
.PlasmicJobsView_freeBox__phhL__lZg1D {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  border-radius: 5px 5px 0px 0px;
  border-width: 0px;
  border-style: solid;
}
.PlasmicJobsView_freeBox__nkGPy__1RiS- {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  padding-left: 20px;
  padding-right: 20px;
  width: 120px;
  flex-shrink: 0;
}
.PlasmicJobsView_text__vgvLq__G4_pP {
  color: #ffffff;
  height: auto;
  width: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_text__vgvLq__G4_pP {
    font-size: 12px;
    color: #ffffff;
  }
}
.PlasmicJobsView_freeBox___5AprN___4mh8 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  width: 80px;
  flex-shrink: 0;
  padding: 0px 20px;
}
.PlasmicJobsView_freeBox__qD86__38xUB {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  width: 100%;
  min-width: 0;
  padding: 0px 20px;
}
.PlasmicJobsView_text__x3Fnc__1RULN {
  color: #ffffff;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicJobsView_text__x3Fnc__1RULN {
    height: auto;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
  }
}
.PlasmicJobsView_jobArtefact__j6Cyb__nGIG8.__wab_instance {
  position: relative;
}

.PlasmicPasswordUpdatePage_root__2CnlG {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
  border-width: 1px;
}
.PlasmicPasswordUpdatePage_root__2CnlG > .__wab_flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.PlasmicPasswordUpdatePage_root__2CnlG > .__wab_flex-container > *,
.PlasmicPasswordUpdatePage_root__2CnlG > .__wab_flex-container > .__wab_slot > *,
.PlasmicPasswordUpdatePage_root__2CnlG > .__wab_flex-container > picture > img,
.PlasmicPasswordUpdatePage_root__2CnlG > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 50px;
}
.PlasmicPasswordUpdatePage_root__2CnlG {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: unset;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
  border-width: 1px;
}
.PlasmicPasswordUpdatePage_appBar__2KdU4.__wab_instance {
  position: relative;
  z-index: 2;
}
.PlasmicPasswordUpdatePage_img__leHX__1mj_c {
  position: relative;
  object-fit: contain;
  height: 50px;
  width: 100px;
}
.PlasmicPasswordUpdatePage_img__leHX__1mj_c > picture > img {
  object-fit: contain;
}
.PlasmicPasswordUpdatePage_img__leHX__1mj_c > .__wab_img-spacer > img {
  object-fit: contain;
}
.PlasmicPasswordUpdatePage_svg__rUqza__3CoOn {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 500px;
  min-height: 0;
}
.PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il > .__wab_flex-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il > .__wab_flex-container > *,
.PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il > .__wab_flex-container > .__wab_slot > *,
.PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il > .__wab_flex-container > picture > img,
.PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 20px;
}
.PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  width: 500px;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il {
    width: 100%;
    min-width: 0;
    padding: 0px 50px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il > .__wab_flex-container {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .PlasmicPasswordUpdatePage_freeBox___4Lbni__3S3Il {
    width: 100%;
    min-width: 0;
    padding: 0px 50px;
  }
}
.PlasmicPasswordUpdatePage_freeBox__rhKi__SDYRF {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicPasswordUpdatePage_text__12RS8 {
  position: relative;
  color: #ffffff;
  font-size: 42px;
}
.PlasmicPasswordUpdatePage_freeBox__htOo9__3mBGF {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  padding: 50px;
  border-width: 1px;
}
.PlasmicPasswordUpdatePage_freeBox__htOo9__3mBGF > .__wab_flex-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: unset;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicPasswordUpdatePage_freeBox__htOo9__3mBGF > .__wab_flex-container > *,
.PlasmicPasswordUpdatePage_freeBox__htOo9__3mBGF > .__wab_flex-container > .__wab_slot > *,
.PlasmicPasswordUpdatePage_freeBox__htOo9__3mBGF > .__wab_flex-container > picture > img,
.PlasmicPasswordUpdatePage_freeBox__htOo9__3mBGF
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 20px;
}
.PlasmicPasswordUpdatePage_freeBox__htOo9__3mBGF {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  background: #ffffff;
  align-content: unset;
  border-radius: 5px;
  padding: 50px;
  border-width: 1px;
}
.PlasmicPasswordUpdatePage_materialUiTextField__ofV25__36pQc.__wab_instance {
  position: relative;
}
.PlasmicPasswordUpdatePage_materialUiTextField__qbFt__2sMFA.__wab_instance {
  position: relative;
}
.PlasmicPasswordUpdatePage_materialUiTextField__dOahV__3Y6v_.__wab_instance {
  position: relative;
}
.PlasmicPasswordUpdatePage_freeBox__t5CJv__37i0F {
  display: flex;
  position: relative;
  flex-direction: row;
}
.PlasmicPasswordUpdatePage_freeBox__t5CJv__37i0F > .__wab_flex-container {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.PlasmicPasswordUpdatePage_freeBox__t5CJv__37i0F > .__wab_flex-container > *,
.PlasmicPasswordUpdatePage_freeBox__t5CJv__37i0F > .__wab_flex-container > .__wab_slot > *,
.PlasmicPasswordUpdatePage_freeBox__t5CJv__37i0F > .__wab_flex-container > picture > img,
.PlasmicPasswordUpdatePage_freeBox__t5CJv__37i0F
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 20px;
}
.PlasmicPasswordUpdatePage_freeBox__t5CJv__37i0F {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicPasswordUpdatePage_updateButton__kzZ_0 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicPasswordUpdatePage_updateButton2__1y-So {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

