.root {
  display: inline-flex;
  flex-direction: row;
  width: auto;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-style: solid;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 0px;
}
.root {
  display: inline-flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-top-style: solid;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px;
}
.freeBox___8KgBg {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 120px;
  align-self: center;
  flex-shrink: 0;
}
.slotTargetType {
  text-align: center;
}
.freeBox__sRxnW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 80px;
  align-self: center;
  flex-shrink: 0;
}
.slotTargetTime {
  text-align: center;
}
.freeBox___0D2Yr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  align-self: center;
  min-width: 0;
}
.freeBox___551Jw {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20px;
  width: auto;
  flex-shrink: 0;
}
