@import url("https://fonts.googleapis.com/css2?family=Roboto%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C700%3B0%2C900&family=Inter%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&display=swap");

.plasmic_tokens {
  --token-yqQ_hnf36: #82c341;
  --plasmic-token-ibeam-green: var(--token-yqQ_hnf36);
  --token-E66s0XP42: #939598;
  --plasmic-token-ibeam-grey: var(--token-E66s0XP42);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-S2OLrIGcf5_font-family: "Roboto", sans-serif;
  --mixin-S2OLrIGcf5_font-size: 16px;
  --mixin-S2OLrIGcf5_font-weight: 400;
  --mixin-S2OLrIGcf5_font-style: normal;
  --mixin-S2OLrIGcf5_color: #535353;
  --mixin-S2OLrIGcf5_text-align: left;
  --mixin-S2OLrIGcf5_text-transform: none;
  --mixin-S2OLrIGcf5_line-height: 1.5;
  --mixin-S2OLrIGcf5_letter-spacing: normal;
  --mixin-S2OLrIGcf5_white-space: pre-wrap;
  --mixin-S2OLrIGcf5_user-select: text;
  --mixin-S2OLrIGcf5_text-decoration-line: none;
  --mixin-S2OLrIGcf5_text-overflow: clip;
  --mixin-JcMFH7VxZN_font-family: "Inter", sans-serif;
  --mixin-JcMFH7VxZN_color: #000000;
  --mixin-JcMFH7VxZN_font-size: 72px;
  --mixin-JcMFH7VxZN_font-weight: 900;
  --mixin-JcMFH7VxZN_letter-spacing: -4px;
  --mixin-JcMFH7VxZN_line-height: 1;
  --mixin-JcMFH7VxZN_white-space: pre-wrap;
  --mixin-g-4V1D8W0g_font-family: "Inter", sans-serif;
  --mixin-g-4V1D8W0g_color: #000000;
  --mixin-g-4V1D8W0g_font-size: 48px;
  --mixin-g-4V1D8W0g_font-weight: 700;
  --mixin-g-4V1D8W0g_letter-spacing: -1px;
  --mixin-g-4V1D8W0g_line-height: 1.1;
  --mixin-g-4V1D8W0g_white-space: pre-wrap;
  --mixin-TqIWSlTL6B_font-family: "Inter", sans-serif;
  --mixin-TqIWSlTL6B_color: #000000;
  --mixin-TqIWSlTL6B_font-size: 32px;
  --mixin-TqIWSlTL6B_font-weight: 600;
  --mixin-TqIWSlTL6B_letter-spacing: -0.8px;
  --mixin-TqIWSlTL6B_line-height: 1.2;
  --mixin-TqIWSlTL6B_white-space: pre-wrap;
  --mixin-852KMCgDctm_font-family: "Inter", sans-serif;
  --mixin-852KMCgDctm_color: #000000;
  --mixin-852KMCgDctm_font-size: 24px;
  --mixin-852KMCgDctm_font-weight: 600;
  --mixin-852KMCgDctm_letter-spacing: -0.5px;
  --mixin-852KMCgDctm_line-height: 1.3;
  --mixin-852KMCgDctm_white-space: pre-wrap;
  --mixin-uclOyNsCzsM_font-family: "Inter", sans-serif;
  --mixin-uclOyNsCzsM_color: #000000;
  --mixin-uclOyNsCzsM_font-size: 20px;
  --mixin-uclOyNsCzsM_font-weight: 600;
  --mixin-uclOyNsCzsM_letter-spacing: -0.3px;
  --mixin-uclOyNsCzsM_line-height: 1.5;
  --mixin-uclOyNsCzsM_white-space: pre-wrap;
  --mixin-Ia5KU0oUu3F_font-family: "Inter", sans-serif;
  --mixin-Ia5KU0oUu3F_color: #000000;
  --mixin-Ia5KU0oUu3F_font-size: 16px;
  --mixin-Ia5KU0oUu3F_font-weight: 600;
  --mixin-Ia5KU0oUu3F_line-height: 1.5;
  --mixin-Ia5KU0oUu3F_white-space: pre-wrap;
  --mixin-9YZJbm-R5TK_color: #0070f3;
  --mixin-9YZJbm-R5TK_white-space: pre-wrap;
  --mixin-nOJEO7Otv2L_color: #3291ff;
  --mixin-nOJEO7Otv2L_white-space: pre-wrap;
  --mixin-qj68ArVPUyJ_border-left-color: #dddddd;
  --mixin-qj68ArVPUyJ_border-left-style: solid;
  --mixin-qj68ArVPUyJ_border-left-width: 3px;
  --mixin-qj68ArVPUyJ_color: #888888;
  --mixin-qj68ArVPUyJ_padding-left: 10px;
  --mixin-qj68ArVPUyJ_white-space: pre-wrap;
  --mixin-Y5s6I1ZwrVh_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-Y5s6I1ZwrVh_border-bottom-color: #dddddd;
  --mixin-Y5s6I1ZwrVh_border-bottom-style: solid;
  --mixin-Y5s6I1ZwrVh_border-bottom-width: 1px;
  --mixin-Y5s6I1ZwrVh_border-left-color: #dddddd;
  --mixin-Y5s6I1ZwrVh_border-left-style: solid;
  --mixin-Y5s6I1ZwrVh_border-left-width: 1px;
  --mixin-Y5s6I1ZwrVh_border-right-color: #dddddd;
  --mixin-Y5s6I1ZwrVh_border-right-style: solid;
  --mixin-Y5s6I1ZwrVh_border-right-width: 1px;
  --mixin-Y5s6I1ZwrVh_border-top-color: #dddddd;
  --mixin-Y5s6I1ZwrVh_border-top-style: solid;
  --mixin-Y5s6I1ZwrVh_border-top-width: 1px;
  --mixin-Y5s6I1ZwrVh_border-bottom-left-radius: 3px;
  --mixin-Y5s6I1ZwrVh_border-bottom-right-radius: 3px;
  --mixin-Y5s6I1ZwrVh_border-top-left-radius: 3px;
  --mixin-Y5s6I1ZwrVh_border-top-right-radius: 3px;
  --mixin-Y5s6I1ZwrVh_font-family: "Inconsolata";
  --mixin-Y5s6I1ZwrVh_padding-bottom: 1px;
  --mixin-Y5s6I1ZwrVh_padding-left: 4px;
  --mixin-Y5s6I1ZwrVh_padding-right: 4px;
  --mixin-Y5s6I1ZwrVh_padding-top: 1px;
  --mixin-Y5s6I1ZwrVh_white-space: pre-wrap;
  --mixin-rkMKReWogK3_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-rkMKReWogK3_border-bottom-color: #dddddd;
  --mixin-rkMKReWogK3_border-bottom-style: solid;
  --mixin-rkMKReWogK3_border-bottom-width: 1px;
  --mixin-rkMKReWogK3_border-left-color: #dddddd;
  --mixin-rkMKReWogK3_border-left-style: solid;
  --mixin-rkMKReWogK3_border-left-width: 1px;
  --mixin-rkMKReWogK3_border-right-color: #dddddd;
  --mixin-rkMKReWogK3_border-right-style: solid;
  --mixin-rkMKReWogK3_border-right-width: 1px;
  --mixin-rkMKReWogK3_border-top-color: #dddddd;
  --mixin-rkMKReWogK3_border-top-style: solid;
  --mixin-rkMKReWogK3_border-top-width: 1px;
  --mixin-rkMKReWogK3_border-bottom-left-radius: 3px;
  --mixin-rkMKReWogK3_border-bottom-right-radius: 3px;
  --mixin-rkMKReWogK3_border-top-left-radius: 3px;
  --mixin-rkMKReWogK3_border-top-right-radius: 3px;
  --mixin-rkMKReWogK3_font-family: "Inconsolata";
  --mixin-rkMKReWogK3_padding-bottom: 3px;
  --mixin-rkMKReWogK3_padding-left: 6px;
  --mixin-rkMKReWogK3_padding-right: 6px;
  --mixin-rkMKReWogK3_padding-top: 3px;
  --mixin-rkMKReWogK3_white-space: pre-wrap;
  --mixin-ar9mK02rr4U_display: flex;
  --mixin-ar9mK02rr4U_flex-direction: column;
  --mixin-ar9mK02rr4U_align-items: stretch;
  --mixin-ar9mK02rr4U_justify-content: flex-start;
  --mixin-ar9mK02rr4U_list-style-position: outside;
  --mixin-ar9mK02rr4U_padding-left: 40px;
  --mixin-ar9mK02rr4U_position: relative;
  --mixin-ar9mK02rr4U_list-style-type: decimal;
  --mixin-ar9mK02rr4U_white-space: pre-wrap;
  --mixin-qWWAyoh3r7Z_display: flex;
  --mixin-qWWAyoh3r7Z_flex-direction: column;
  --mixin-qWWAyoh3r7Z_align-items: stretch;
  --mixin-qWWAyoh3r7Z_justify-content: flex-start;
  --mixin-qWWAyoh3r7Z_list-style-position: outside;
  --mixin-qWWAyoh3r7Z_padding-left: 40px;
  --mixin-qWWAyoh3r7Z_position: relative;
  --mixin-qWWAyoh3r7Z_list-style-type: disc;
  --mixin-qWWAyoh3r7Z_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-S2OLrIGcf5_font-family);
  font-size: var(--mixin-S2OLrIGcf5_font-size);
  font-weight: var(--mixin-S2OLrIGcf5_font-weight);
  font-style: var(--mixin-S2OLrIGcf5_font-style);
  color: var(--mixin-S2OLrIGcf5_color);
  text-align: var(--mixin-S2OLrIGcf5_text-align);
  text-transform: var(--mixin-S2OLrIGcf5_text-transform);
  line-height: var(--mixin-S2OLrIGcf5_line-height);
  letter-spacing: var(--mixin-S2OLrIGcf5_letter-spacing);
  white-space: var(--mixin-S2OLrIGcf5_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  font-family: var(--mixin-JcMFH7VxZN_font-family);
  color: var(--mixin-JcMFH7VxZN_color);
  font-size: var(--mixin-JcMFH7VxZN_font-size);
  font-weight: var(--mixin-JcMFH7VxZN_font-weight);
  letter-spacing: var(--mixin-JcMFH7VxZN_letter-spacing);
  line-height: var(--mixin-JcMFH7VxZN_line-height);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  font-family: var(--mixin-g-4V1D8W0g_font-family);
  color: var(--mixin-g-4V1D8W0g_color);
  font-size: var(--mixin-g-4V1D8W0g_font-size);
  font-weight: var(--mixin-g-4V1D8W0g_font-weight);
  letter-spacing: var(--mixin-g-4V1D8W0g_letter-spacing);
  line-height: var(--mixin-g-4V1D8W0g_line-height);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  font-family: var(--mixin-TqIWSlTL6B_font-family);
  color: var(--mixin-TqIWSlTL6B_color);
  font-size: var(--mixin-TqIWSlTL6B_font-size);
  font-weight: var(--mixin-TqIWSlTL6B_font-weight);
  letter-spacing: var(--mixin-TqIWSlTL6B_letter-spacing);
  line-height: var(--mixin-TqIWSlTL6B_line-height);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-family: var(--mixin-852KMCgDctm_font-family);
  color: var(--mixin-852KMCgDctm_color);
  font-size: var(--mixin-852KMCgDctm_font-size);
  font-weight: var(--mixin-852KMCgDctm_font-weight);
  letter-spacing: var(--mixin-852KMCgDctm_letter-spacing);
  line-height: var(--mixin-852KMCgDctm_line-height);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-family: var(--mixin-uclOyNsCzsM_font-family);
  color: var(--mixin-uclOyNsCzsM_color);
  font-size: var(--mixin-uclOyNsCzsM_font-size);
  font-weight: var(--mixin-uclOyNsCzsM_font-weight);
  letter-spacing: var(--mixin-uclOyNsCzsM_letter-spacing);
  line-height: var(--mixin-uclOyNsCzsM_line-height);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-family: var(--mixin-Ia5KU0oUu3F_font-family);
  color: var(--mixin-Ia5KU0oUu3F_color);
  font-size: var(--mixin-Ia5KU0oUu3F_font-size);
  font-weight: var(--mixin-Ia5KU0oUu3F_font-weight);
  line-height: var(--mixin-Ia5KU0oUu3F_line-height);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  color: var(--mixin-9YZJbm-R5TK_color);
}

:where(.root_reset) a:where(.a):hover,
a:where(.root_reset.a):hover,
:where(.root_reset .__wab_expr_html_text) a:hover,
:where(.root_reset_tags) a:hover,
a:where(.root_reset_tags):hover {
  color: var(--mixin-nOJEO7Otv2L_color);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-qj68ArVPUyJ_color);
  padding-left: var(--mixin-qj68ArVPUyJ_padding-left);
  border-left: var(--mixin-qj68ArVPUyJ_border-left-width)
    var(--mixin-qj68ArVPUyJ_border-left-style)
    var(--mixin-qj68ArVPUyJ_border-left-color);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-Y5s6I1ZwrVh_font-family);
  border-radius: var(--mixin-Y5s6I1ZwrVh_border-top-left-radius)
    var(--mixin-Y5s6I1ZwrVh_border-top-right-radius)
    var(--mixin-Y5s6I1ZwrVh_border-bottom-right-radius)
    var(--mixin-Y5s6I1ZwrVh_border-bottom-left-radius);
  padding: var(--mixin-Y5s6I1ZwrVh_padding-top)
    var(--mixin-Y5s6I1ZwrVh_padding-right)
    var(--mixin-Y5s6I1ZwrVh_padding-bottom)
    var(--mixin-Y5s6I1ZwrVh_padding-left);
  border-top: var(--mixin-Y5s6I1ZwrVh_border-top-width)
    var(--mixin-Y5s6I1ZwrVh_border-top-style)
    var(--mixin-Y5s6I1ZwrVh_border-top-color);
  border-right: var(--mixin-Y5s6I1ZwrVh_border-right-width)
    var(--mixin-Y5s6I1ZwrVh_border-right-style)
    var(--mixin-Y5s6I1ZwrVh_border-right-color);
  border-bottom: var(--mixin-Y5s6I1ZwrVh_border-bottom-width)
    var(--mixin-Y5s6I1ZwrVh_border-bottom-style)
    var(--mixin-Y5s6I1ZwrVh_border-bottom-color);
  border-left: var(--mixin-Y5s6I1ZwrVh_border-left-width)
    var(--mixin-Y5s6I1ZwrVh_border-left-style)
    var(--mixin-Y5s6I1ZwrVh_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-rkMKReWogK3_font-family);
  border-radius: var(--mixin-rkMKReWogK3_border-top-left-radius)
    var(--mixin-rkMKReWogK3_border-top-right-radius)
    var(--mixin-rkMKReWogK3_border-bottom-right-radius)
    var(--mixin-rkMKReWogK3_border-bottom-left-radius);
  padding: var(--mixin-rkMKReWogK3_padding-top)
    var(--mixin-rkMKReWogK3_padding-right)
    var(--mixin-rkMKReWogK3_padding-bottom)
    var(--mixin-rkMKReWogK3_padding-left);
  border-top: var(--mixin-rkMKReWogK3_border-top-width)
    var(--mixin-rkMKReWogK3_border-top-style)
    var(--mixin-rkMKReWogK3_border-top-color);
  border-right: var(--mixin-rkMKReWogK3_border-right-width)
    var(--mixin-rkMKReWogK3_border-right-style)
    var(--mixin-rkMKReWogK3_border-right-color);
  border-bottom: var(--mixin-rkMKReWogK3_border-bottom-width)
    var(--mixin-rkMKReWogK3_border-bottom-style)
    var(--mixin-rkMKReWogK3_border-bottom-color);
  border-left: var(--mixin-rkMKReWogK3_border-left-width)
    var(--mixin-rkMKReWogK3_border-left-style)
    var(--mixin-rkMKReWogK3_border-left-color);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-ar9mK02rr4U_display);
  flex-direction: var(--mixin-ar9mK02rr4U_flex-direction);
  align-items: var(--mixin-ar9mK02rr4U_align-items);
  justify-content: var(--mixin-ar9mK02rr4U_justify-content);
  list-style-position: var(--mixin-ar9mK02rr4U_list-style-position);
  padding-left: var(--mixin-ar9mK02rr4U_padding-left);
  position: var(--mixin-ar9mK02rr4U_position);
  list-style-type: var(--mixin-ar9mK02rr4U_list-style-type);
  flex-column-gap: var(--mixin-ar9mK02rr4U_flex-column-gap);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-qWWAyoh3r7Z_display);
  flex-direction: var(--mixin-qWWAyoh3r7Z_flex-direction);
  align-items: var(--mixin-qWWAyoh3r7Z_align-items);
  justify-content: var(--mixin-qWWAyoh3r7Z_justify-content);
  list-style-position: var(--mixin-qWWAyoh3r7Z_list-style-position);
  padding-left: var(--mixin-qWWAyoh3r7Z_padding-left);
  position: var(--mixin-qWWAyoh3r7Z_position);
  list-style-type: var(--mixin-qWWAyoh3r7Z_list-style-type);
  flex-column-gap: var(--mixin-qWWAyoh3r7Z_flex-column-gap);
}
