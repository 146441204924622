.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  height: auto;
  background: #ffffff;
  transition-property: all;
  transition-duration: 1s;
  box-shadow: 2px 4px 4px 2px #00000080;
  position: relative;
  min-width: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
}
.freeBox___3Qks1 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  align-self: center;
  width: 100%;
  padding-left: 20px;
  min-width: 0;
}
.img___5PibA {
  position: relative;
  object-fit: contain;
  height: 50px;
  width: 100px;
  flex-shrink: 0;
}
.img___5PibA > picture > img {
  object-fit: contain;
}
.img___5PibA > :global(.__wab_img-spacer) > img {
  object-fit: contain;
}
.text {
  position: relative;
  font-size: 48px;
  color: #535353fa;
  font-weight: 400;
}
.freeBox__rokOg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-self: center;
  width: 100%;
  padding-right: 20px;
  min-width: 0;
}
.freeBox__rokOg > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-content: unset;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox__rokOg > :global(.__wab_flex-container) > *,
.freeBox__rokOg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__rokOg > :global(.__wab_flex-container) > picture > img,
.freeBox__rokOg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.freeBox__rokOg {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
  width: 100%;
  align-content: unset;
  padding-right: 20px;
  min-width: 0;
}
.svg__xgpr8 {
  position: relative;
  object-fit: cover;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
