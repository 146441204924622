.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
  border-width: 1px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 50px;
}
.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: unset;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
  border-width: 1px;
}
.appBar:global(.__wab_instance) {
  position: relative;
  z-index: 2;
}
.img__leHX {
  position: relative;
  object-fit: contain;
  height: 50px;
  width: 100px;
}
.img__leHX > picture > img {
  object-fit: contain;
}
.img__leHX > :global(.__wab_img-spacer) > img {
  object-fit: contain;
}
.svg__rUqza {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox___4Lbni {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 500px;
  min-height: 0;
}
.freeBox___4Lbni > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox___4Lbni > :global(.__wab_flex-container) > *,
.freeBox___4Lbni > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___4Lbni > :global(.__wab_flex-container) > picture > img,
.freeBox___4Lbni
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox___4Lbni {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  width: 500px;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___4Lbni {
    width: 100%;
    min-width: 0;
    padding: 0px 50px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___4Lbni > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___4Lbni {
    width: 100%;
    min-width: 0;
    padding: 0px 50px;
  }
}
.freeBox__rhKi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.text {
  position: relative;
  color: #ffffff;
  font-size: 42px;
}
.freeBox__htOo9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  padding: 50px;
  border-width: 1px;
}
.freeBox__htOo9 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: unset;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__htOo9 > :global(.__wab_flex-container) > *,
.freeBox__htOo9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__htOo9 > :global(.__wab_flex-container) > picture > img,
.freeBox__htOo9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox__htOo9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  background: #ffffff;
  align-content: unset;
  border-radius: 5px;
  padding: 50px;
  border-width: 1px;
}
.materialUiTextField__ofV25:global(.__wab_instance) {
  position: relative;
}
.materialUiTextField__qbFt:global(.__wab_instance) {
  position: relative;
}
.materialUiTextField__dOahV:global(.__wab_instance) {
  position: relative;
}
.freeBox__t5CJv {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__t5CJv > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox__t5CJv > :global(.__wab_flex-container) > *,
.freeBox__t5CJv > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__t5CJv > :global(.__wab_flex-container) > picture > img,
.freeBox__t5CJv
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.freeBox__t5CJv {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.updateButton {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.updateButton2 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
