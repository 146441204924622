.rootBox {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 500px;
  position: relative;
  box-shadow: none;
  background: #ffffff;
  cursor: pointer;
  transition-property: all;
  transition-duration: 1s;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  backdrop-filter: none;
  filter: none;
  -webkit-backdrop-filter: none;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-radius: 5px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .rootBox {
    width: auto;
    display: inline-flex;
  }
}
.freeBox__acEpO {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.freeBox__acEpO > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox__acEpO > :global(.__wab_flex-container) > *,
.freeBox__acEpO > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__acEpO > :global(.__wab_flex-container) > picture > img,
.freeBox__acEpO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.freeBox__acEpO {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  width: auto;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}
.freeBox___3Xzm4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  min-width: 0;
}
.freeBox__wetvu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  padding-left: 20px;
  padding-right: 20px;
}
.text__ys2AQ {
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__ys2AQ {
    font-size: 12px;
  }
}
.freeBox__lhzu4 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: unset;
  height: 100%;
  min-height: 0;
}
.freeBox__uu0A {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 35px;
  width: 98px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__uu0A {
    height: 25px;
    width: auto;
    flex-shrink: 0;
  }
}
.slotTargetJobNumber {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetJobNumber {
    font-size: 18px;
  }
}
.freeBox___34Rq6 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  min-width: 0;
}
.freeBox__tyYIq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  padding: 0px 20px;
}
.text__o7JV7 {
  color: #ffffff;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__o7JV7 {
    height: auto;
    font-size: 12px;
  }
}
.freeBox__cb9J {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: unset;
  height: 100%;
  min-height: 0;
  padding: 10px;
}
.freeBox__rJSfz {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20px;
  width: 37px;
  flex-shrink: 0;
}
.slotTargetYear {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetYear {
    font-size: 12px;
  }
}
.slotTargetDate {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetDate {
    font-size: 18px;
  }
}
.freeBox__zARt {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20px;
  width: 83px;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__zARt {
    height: 20px;
    width: auto;
    flex-shrink: 0;
  }
}
.slotTargetTime {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetTime {
    font-size: 12px;
  }
}
.freeBox__hw4Kd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  width: 100%;
  min-width: 0;
}
.freeBox__yl2U {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  padding: 0px 20px;
}
.text__biOv {
  color: #ffffff;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__biOv {
    height: auto;
    font-size: 12px;
  }
}
.freeBox__oLk1D {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: unset;
  height: 100%;
  width: 180px;
  min-height: 0;
  padding: 10px;
}
.southAustralia {
  position: relative;
  text-align: center;
  height: 20px;
  width: auto;
  flex-shrink: 0;
}
.slotTargetStreet {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetStreet {
    font-size: 18px;
  }
}
.freeBox__l08X8 {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 20px;
  width: auto;
  flex-shrink: 0;
}
.slotTargetSuburb {
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetSuburb {
    font-size: 12px;
  }
}
