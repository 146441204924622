.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  min-width: 0;
}
.freeBox__nxghs {
  display: flex;
  position: relative;
  flex-direction: column;
  background: linear-gradient(0deg, #939598 0%, #8da780 35%, #82c341 100%) 0% 0% /
      100% 100% repeat,
    #82c341;
  height: 100%;
  min-height: 0;
}
.freeBox__nxghs > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: unset;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__nxghs > :global(.__wab_flex-container) > *,
.freeBox__nxghs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nxghs > :global(.__wab_flex-container) > picture > img,
.freeBox__nxghs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox__nxghs {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #939598 0%, #8da780 35%, #82c341 100%) 0% 0% /
      100% 100% repeat,
    #82c341;
  height: 100%;
  align-content: unset;
  min-height: 0;
}
.freeBox__ndtWe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.welcome {
  position: relative;
  font-size: 48px;
  color: #ffffff;
  text-align: center;
}
.text {
  position: relative;
  text-align: center;
  color: #ffffff;
}
.freeBox__qun8F {
  display: flex;
  position: relative;
  flex-direction: column;
  align-self: center;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  padding: 50px;
  border-width: 1px;
}
.freeBox__qun8F > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: unset;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__qun8F > :global(.__wab_flex-container) > *,
.freeBox__qun8F > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qun8F > :global(.__wab_flex-container) > picture > img,
.freeBox__qun8F
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox__qun8F {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: unset;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  padding: 50px;
  border-width: 1px;
}
.freeBox__e0W28 {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__e0W28 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__e0W28 > :global(.__wab_flex-container) > *,
.freeBox__e0W28 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__e0W28 > :global(.__wab_flex-container) > picture > img,
.freeBox__e0W28
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox__e0W28 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBoxloading__e0W2809Hv2 {
  display: none;
}
.freeBoxloading__e0W2809Hv2 {
  display: none;
}
.materialUiTextField___7MwH:global(.__wab_instance) {
  position: relative;
}
.materialUiTextField__jf908:global(.__wab_instance) {
  position: relative;
}
.freeBox__m8B8G {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBoxloading__m8B8G09Hv2 {
  width: 200px;
  align-self: center;
}
.materialUiCircularProgressloading:global(.__wab_instance) {
  align-self: center;
}
.signInButton {
  position: relative;
  background: #82c341;
  color: #ffffff;
  font-weight: 400;
  height: auto;
  border-radius: 5px;
}
.signInButtonloading {
  display: none;
}
.img {
  position: relative;
  object-fit: scale-down;
  width: 180px;
  height: 81px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: scale-down;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: scale-down;
}
