.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
  border-width: 1px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 50px;
}
.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: unset;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  min-width: 0;
  min-height: 0;
  border-width: 1px;
}
.appBar:global(.__wab_instance) {
  position: relative;
  z-index: 2;
}
.img__dsbij {
  position: relative;
  object-fit: contain;
  height: 50px;
  width: 100px;
}
.img__dsbij > picture > img {
  object-fit: contain;
}
.img__dsbij > :global(.__wab_img-spacer) > img {
  object-fit: contain;
}
.svg__o2S54 {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox___0FHfM {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 500px;
  min-height: 0;
}
.freeBox___0FHfM > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox___0FHfM > :global(.__wab_flex-container) > *,
.freeBox___0FHfM > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0FHfM > :global(.__wab_flex-container) > picture > img,
.freeBox___0FHfM
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox___0FHfM {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  width: 500px;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___0FHfM {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___0FHfM > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___0FHfM {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 0;
  }
}
.freeBox__jyNoq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.text {
  position: relative;
  color: #ffffff;
  font-size: 42px;
}
.freeBox__bySWw {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.textInput__tlB {
  width: 180px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px 0px 0px 10px;
  border: 2px solid var(--token-E66s0XP42);
}
.button__pDt5R {
  position: relative;
  background: var(--token-E66s0XP42);
  color: #ffffff;
  font-weight: 700;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid var(--token-E66s0XP42);
}
.freeBox___0Ocr {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 2;
  margin-bottom: 20px;
}
.freeBox___0Ocr > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox___0Ocr > :global(.__wab_flex-container) > *,
.freeBox___0Ocr > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0Ocr > :global(.__wab_flex-container) > picture > img,
.freeBox___0Ocr
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox___0Ocr {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  z-index: 2;
  margin-bottom: 20px;
}
.jobItem__fhKM:global(.__wab_instance) {
  position: relative;
  z-index: 2;
}
