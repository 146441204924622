.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  transition-property: all;
  transition-duration: 1s;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-width: 1px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: unset;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 50px);
  height: calc(100% + 50px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 50px;
}
.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: unset;
  background: linear-gradient(
      0deg,
      var(--token-E66s0XP42) 0%,
      var(--token-yqQ_hnf36) 100%
    )
    0% 0% / 100% 100% repeat;
  transition-property: all;
  transition-duration: 1s;
  min-width: 0;
  min-height: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-width: 1px;
}
.appBar:global(.__wab_instance) {
  position: relative;
  z-index: 2;
}
.img__joNiu {
  position: relative;
  object-fit: contain;
  height: 50px;
  width: 100px;
}
.img__joNiu > picture > img {
  object-fit: contain;
}
.img__joNiu > :global(.__wab_img-spacer) > img {
  object-fit: contain;
}
.svg__dGkee {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__wtvI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBoxloading__wtvIgcpuk {
  height: 100%;
  width: auto;
  min-height: 0;
}
.freeBox__i5YbL {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
.freeBox__i5YbL > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-height: 0;
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.freeBox__i5YbL > :global(.__wab_flex-container) > *,
.freeBox__i5YbL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__i5YbL > :global(.__wab_flex-container) > picture > img,
.freeBox__i5YbL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 20px;
}
.freeBox__i5YbL {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  min-height: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__i5YbL {
    padding: 20px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__i5YbL {
    padding: 20px;
  }
}
.freeBoxloading__i5YbLgcpuk {
  display: none;
}
.freeBoxloading__i5YbLgcpuk {
  display: none;
}
.freeBox__vopsq {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.freeBox__y7Suo {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__y7Suo {
    justify-content: center;
    align-items: center;
  }
}
.slotTargetJobNumber {
  font-size: 42px;
  color: #ffffff;
}
@media (min-width: 0px) and (max-width: 768px) {
  .slotTargetJobNumber {
    text-align: center;
  }
}
.svg__p3XMb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.freeBox__qc5Ao {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__qc5Ao > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox__qc5Ao > :global(.__wab_flex-container) > *,
.freeBox__qc5Ao > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__qc5Ao > :global(.__wab_flex-container) > picture > img,
.freeBox__qc5Ao
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.freeBox__qc5Ao {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__qc5Ao > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
    align-content: unset;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__qc5Ao {
    justify-content: center;
    align-items: center;
    align-content: unset;
  }
}
.text__fu29 {
  position: relative;
  color: #ffffff;
}
.text__st5Wd {
  position: relative;
  color: #ffffff;
}
.text__qPdJn {
  position: relative;
  color: #ffffff;
}
.freeBox__jZiH {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__jZiH > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.freeBox__jZiH > :global(.__wab_flex-container) > *,
.freeBox__jZiH > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jZiH > :global(.__wab_flex-container) > picture > img,
.freeBox__jZiH
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
.freeBox__jZiH {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  align-content: unset;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__jZiH > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-content: center;
    align-items: unset;
    justify-content: center;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__jZiH {
    flex-wrap: wrap;
    align-content: center;
    align-items: unset;
    justify-content: center;
  }
}
.freeBox___7NjMp {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
}
.text__fw6Ei {
  position: relative;
  color: #ffffff;
}
.text__bzhyG {
  position: relative;
  color: #ffffff;
}
.freeBox__zr48H {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 450px;
  height: 300px;
  flex-shrink: 0;
  border-width: 0px;
  border-style: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__zr48H {
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
.artefactsBox {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  position: relative;
  box-shadow: none;
  background: #ffffff;
  cursor: pointer;
  transition-property: all;
  transition-duration: 1s;
  transform: translateX(0px) translateY(0px) translateZ(0px);
  backdrop-filter: none;
  filter: none;
  padding-bottom: 0px;
  margin-bottom: 20px;
  -webkit-backdrop-filter: none;
  -webkit-transition-property: all;
  -webkit-transition-duration: 1s;
  border-radius: 5px;
}
.freeBox__phhL {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  border-radius: 5px 5px 0px 0px;
  border-width: 0px;
  border-style: solid;
}
.freeBox__phhL > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox__phhL > :global(.__wab_flex-container) > *,
.freeBox__phhL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__phhL > :global(.__wab_flex-container) > picture > img,
.freeBox__phhL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
.freeBox__phhL {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  border-radius: 5px 5px 0px 0px;
  border-width: 0px;
  border-style: solid;
}
.freeBox__nkGPy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  padding-left: 20px;
  padding-right: 20px;
  width: 120px;
  flex-shrink: 0;
}
.text__vgvLq {
  color: #ffffff;
  height: auto;
  width: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__vgvLq {
    font-size: 12px;
    color: #ffffff;
  }
}
.freeBox___5AprN {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  width: 80px;
  flex-shrink: 0;
  padding: 0px 20px;
}
.freeBox__qD86 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background: var(--token-E66s0XP42);
  width: 100%;
  min-width: 0;
  padding: 0px 20px;
}
.text__x3Fnc {
  color: #ffffff;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__x3Fnc {
    height: auto;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
  }
}
.jobArtefact__j6Cyb:global(.__wab_instance) {
  position: relative;
}
